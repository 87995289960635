import React from "react";
import BookOutCard from "../reusables/BookOutCard";
import tlt from "../../assets/images/content/tlt.png";
import dd from "../../assets/images/content/dd.png";
import ds from "../../assets/images/content/ds.png";
import wft from "../../assets/images/content/wft.png";

const BooksOut = () => {
  return (
    <section className="hero-books section-spacing-100 no-padding-top">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-body section-spacing-40">
              <div className="outbooks-cards-container">
                <BookOutCard
                  cover={tlt}
                  cardClass="btreasure"
                  bookTitle="The Lifetime Treasure"
                  bookSubtitle="Learn and master the 18 skills that pay off forever but school will not teach you"
                  bookLink="https://TheLifetimeTreasure.com"
                  cardCtaClass="lyellow"
                />
                <BookOutCard
                  cover={dd}
                  cardClass="bdaddaughter"
                  bookTitle="Dad Daughter"
                  bookSubtitle="200 Life Lessons Every Father Must Teach His Daughter"
                  bookLink="https://DadDaughterBook.com"
                  cardCtaClass="lyellow"
                />
                <BookOutCard
                  cover={ds}
                  cardClass="bdaddaughter"
                  bookTitle="Dad Son"
                  bookSubtitle="200 Life Lessons Every Father Must Teach His Son"
                  bookLink="https://DadSonBook.com"
                  cardCtaClass="lyellow"
                />
              </div>
            </div>
            <div className="section-footer center width-300 margin-auto">
              <div className="wft">
                <img
                  src={wft}
                  alt="Word For Today Publications"
                  className="wft-img"
                />
              </div>
              <p className="rich-small center uppercase">
                books by malidad.com are published, marketed and distributed by
                word for today publications limited{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BooksOut;
